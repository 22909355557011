import { memo, SyntheticEvent, useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { IconButton, ImageListItem, Stack, styled, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop'
import { useTranslation } from 'react-i18next'

import Icon from 'src/@core/components/icon'

import useImageEditor from 'src/hooks/images/useImageEditor'

import { EditorBackground, CloseButton, ModalContent } from 'src/components/common/modals/image-editor/styled'

import { canvasPreview } from 'src/utils/canvasPreview'

import 'react-image-crop/dist/ReactCrop.css'

const ToolbarIcon = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary[500]}`,
  borderRadius: theme.spacing(1.5),
  color: theme.palette.primary[500]
}))

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius
}))

type ImageEditorModalProps = {
  imgSrc?: string | null
  aspectRatio?: number
  isOpen: boolean
  isCircle?: boolean
  onClose: () => void
  onSubmit?: (url: string) => Promise<void>
  isSubmitting?: boolean
}

const ImageCropperModal = ({
  imgSrc,
  aspectRatio = 16 / 9,
  isOpen,
  isSubmitting,
  isCircle = false,
  onClose,
  onSubmit
}: ImageEditorModalProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const {
    rotate,
    scaleX,
    scaleY,
    setRotate,
    setScaleX,
    setScaleY,
    crop,
    setCrop,
    setCompletedCrop,
    previewCanvasRef,
    imgRef,
    handleSubmit,
    completedCrop,
    saveDisabled
  } = useImageEditor({
    onClose,
    onSubmit
  })

  const [isFlippedX, setIsFlippedX] = useState(false)
  const [isFlippedY, setIsFlippedY] = useState(false)

  useEffect(() => {
    setIsFlippedX(false)
    setIsFlippedY(false)
  }, [imgSrc])

  const flipX = () => setIsFlippedX(!isFlippedX)
  const flipY = () => setIsFlippedY(!isFlippedY)
  const rotateLeft = () => setRotate(rotate + 90)
  const rotateRight = () => setRotate(rotate - 90)
  const zoomIn = () => {
    const newScaleX = scaleX + 0.1
    const newScaleY = scaleY + 0.1
    if (newScaleX <= 3 && newScaleY <= 3) {
      setScaleX(newScaleX)
      setScaleY(newScaleY)
    }
  }
  const zoomOut = () => {
    const newScaleX = Math.max(scaleX - 0.1, 0.1)
    const newScaleY = Math.max(scaleY - 0.1, 0.1)
    setScaleX(newScaleX)
    setScaleY(newScaleY)
  }

  const onImageLoad = async (e: SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90
        },
        aspectRatio,
        width,
        height
      ),
      width,
      height
    )

    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current

    setCrop(crop)

    if (!image || !previewCanvas) return

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    setCompletedCrop(convertToPixelCrop(crop, image.width, image.height))
    const offscreen = new OffscreenCanvas(crop.width * scaleX, crop.height * scaleY)
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    )

    if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scaleX, scaleY, rotate)
    }
  }

  if (!imgSrc) return null

  return (
    <Modal keepMounted={false} open={isOpen} onClose={onClose}>
      <ModalContent>
        <CloseButton>
          <CloseIconButton onClick={onClose} disableRipple aria-label={t('extracted.close')}>
            <Icon fontSize={theme.typography.h5.fontSize} icon='tabler:x' />
          </CloseIconButton>
        </CloseButton>
        <Stack gap={theme.spacing(8)}>
          <Typography variant='h3'>{t('PAGE.EDITOR.IMAGE.edit')}</Typography>
          <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
        </Stack>
        <Box display='flex' flexDirection='column' gap={theme.spacing(4)}>
          <Stack direction='row' alignItems='center' justifyContent='center' position='relative'>
            <EditorBackground />
            <ReactCrop
              crop={crop}
              circularCrop={isCircle}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={c => setCompletedCrop(c)}
              aspect={aspectRatio}
              keepSelection
            >
              <ImageListItem
                component={'img'}
                src={imgSrc}
                ref={imgRef}
                onLoad={onImageLoad}
                alt={t('extracted.cropping-image')}
                crossOrigin='anonymous'
                style={{
                  transition: 'all 300ms ease-in-out',
                  transform: `rotate(${rotate}deg) scaleX(${isFlippedX ? -scaleX : scaleX}) scaleY(${
                    isFlippedY ? -scaleY : scaleY
                  })`,
                  maxHeight: 500
                }}
              />
            </ReactCrop>
          </Stack>
          <Box alignItems='center' display='flex' gap={theme.spacing(6)} justifyContent='center'>
            <Box display='flex' gap={theme.spacing(2)}>
              <ToolbarIcon onClick={rotateLeft}>
                <Icon fontSize={theme.typography.h4.fontSize} icon='tabler:rotate-clockwise-2' />
              </ToolbarIcon>
              <ToolbarIcon onClick={rotateRight}>
                <Icon fontSize={theme.typography.h4.fontSize} icon='tabler:rotate-2' />
              </ToolbarIcon>
            </Box>
            <Box display='flex' gap={theme.spacing(2)}>
              <ToolbarIcon onClick={flipX}>
                <Icon fontSize={theme.typography.h4.fontSize} icon='tabler:flip-vertical' />
              </ToolbarIcon>
              <ToolbarIcon onClick={flipY}>
                <Icon fontSize={theme.typography.h4.fontSize} icon='tabler:flip-horizontal' />
              </ToolbarIcon>
            </Box>
            <Box display='flex' gap={theme.spacing(2)}>
              <ToolbarIcon onClick={zoomIn}>
                <Icon fontSize={theme.typography.h4.fontSize} icon='tabler:zoom-in' />
              </ToolbarIcon>
              <ToolbarIcon onClick={zoomOut}>
                <Icon fontSize={theme.typography.h4.fontSize} icon='tabler:zoom-out' />
              </ToolbarIcon>
            </Box>
          </Box>
        </Box>
        <Stack justifyContent='flex-end' direction={{ xs: 'column', md: 'row' }} gap={theme.spacing(4)}>
          <Button disabled={isSubmitting} variant='outlined' onClick={onClose}>
            {t('ACTION.cancel')}
          </Button>
          <LoadingButton loading={isSubmitting} variant='contained' onClick={handleSubmit} disabled={saveDisabled}>
            {t('ACTION.save_n_close')}
          </LoadingButton>
        </Stack>
      </ModalContent>
    </Modal>
  )
}

export default memo(ImageCropperModal)

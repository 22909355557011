import { createQueryKeys } from '@lukemorales/query-key-factory'

const communities = createQueryKeys('communities', {
  all: {
    queryKey: null
  },
  page: (communityId?: number) => {
    return { queryKey: [{ communityId }] }
  },
  stats: (communityId?: number) => {
    return { queryKey: [{ communityId }] }
  }
})

export default communities

import { AxiosResponse } from 'axios'

import { requestUrls } from '../configs/url'
import { CommunityNotificationSettings, NotificationSettingsType } from '../types/settings'

import service from './service'
import authService from './auth-service'

export type GetNotificationSettingsResponse = ResponseResult<CommunityNotificationSettings>

import { FilterParams, ResponseResult } from 'src/types/common/types'
import { Community, CommunityCreateValues, CommunityStats, CommunityUpdate } from 'src/types/community'

export type UpdateNotificationSettingsRequest = { notification_settings_type: NotificationSettingsType }
export type UpdateNotificationSettingsResponse = ResponseResult<boolean>

export type GetCommunitiesRequest = FilterParams<(keyof Community | '**')[]>
export type GetCommunitiesResponse = ResponseResult<Community[]>
export type CommunityRequest = { id: number }
export type CommunityStatsRequest = { community_id: number }
export type CommunityStatsResponse = ResponseResult<CommunityStats>
export type CommunityResponse = ResponseResult<Community>
export type UpdateCommunityResponse = ResponseResult<number>
export type BooleanCommunityResponse = ResponseResult<boolean>

const communityService = {
  getAll: async (requestBody: GetCommunitiesRequest): Promise<AxiosResponse<GetCommunitiesResponse>> => {
    return service.post(requestUrls.getCommunities, requestBody)
  },

  getOne: async (requestBody: CommunityRequest): Promise<AxiosResponse<CommunityResponse>> => {
    return service.post(requestUrls.getCommunity, requestBody)
  },
  updateNotificationSettings: (
    requestBody: UpdateNotificationSettingsRequest
  ): Promise<AxiosResponse<UpdateNotificationSettingsResponse>> =>
    service.post(requestUrls.updateCommunityNotificationSettings, {
      ...requestBody,
      token: authService.getUserToken()
    }),
  getNotificationSettings: (): Promise<AxiosResponse<GetNotificationSettingsResponse>> => {
    return service.get(requestUrls.getCommunityNotificationSettings, {
      params: {
        token: authService.getUserToken(),
        'ngsw-bypass': true
      }
    })
  },

  update: async (requestBody: CommunityUpdate): Promise<AxiosResponse<UpdateCommunityResponse>> => {
    return service.post(requestUrls.updateCommunity, requestBody)
  },

  create: async (requestBody: CommunityCreateValues): Promise<AxiosResponse<CommunityResponse>> => {
    return service.post(requestUrls.addCommunity, requestBody)
  },

  delete: async (requestBody: CommunityRequest): Promise<AxiosResponse<BooleanCommunityResponse>> => {
    return service.post(requestUrls.deleteCommunity, requestBody)
  },

  join: async (requestBody: CommunityRequest): Promise<AxiosResponse<BooleanCommunityResponse>> => {
    return service.post(requestUrls.joinCommunity, { community_id: requestBody.id })
  },

  leave: async (requestBody: CommunityRequest): Promise<AxiosResponse<BooleanCommunityResponse>> => {
    return service.post(requestUrls.leaveCommunity, { community_id: requestBody.id })
  },
  getStats: async (requestBody: CommunityStatsRequest): Promise<AxiosResponse<CommunityStatsResponse>> => {
    return service.post(requestUrls.getCommunityStats, requestBody)
  }
}

export default communityService

import { AxiosResponse } from 'axios'

import {
  LoginRequest,
  LoginResponse,
  LogoutResponse,
  ProlongateRequest,
  ProlongateResponse,
  RegisterActivateRequest,
  RegisterActivateResponse,
  RegisterRequest,
  RegisterVerifyRequest,
  RegisterVerifyResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  UpdatePasswordRequest,
  UpdatePasswordResponse
} from 'src/context/types'

import authConfig from 'src/configs/auth'

import { requestUrls as globalRequestUrls } from '../configs/url'

import service from './service'

import { LocalMentorUserDataType, LocalUserType } from 'src/types/apps/userTypes'

const requestUrls = {
  login: '/entity_api/auth/login',
  logout: '/entity_api/auth/logout',
  prolongate: '/entity_api/auth/prolongate',
  registerSingle: '/entity_api/auth/register/single',
  registerContentCreator: '/entity_api/auth/register/content_creator',
  registerDouble: '/entity_api/auth/register/double',
  registerActivate: '/entity_api/auth/register/activate',
  registerVerify: 'entity_api/auth/register/verify',
  resetPassword: '/entity_api/auth/password/reset',

  previewLogin: '/entity_api/preview/customer/create' // Request to create preview user
}

const authService = {
  getUserToken: (): string | null => {
    if (typeof window === 'undefined') {
      return null
    }

    const isPreviewMode = sessionStorage.getItem('preview') === 'true'
    const storage = isPreviewMode ? sessionStorage : localStorage

    const userFromStorage = storage?.getItem(authConfig.storageTokenKeyName)

    if (userFromStorage) {
      const parsedUser = JSON.parse(userFromStorage) as LocalUserType

      return parsedUser?.token || null
    }

    return null
  },
  login: async (requestBody: LoginRequest): Promise<LoginResponse> => {
    return service.post(requestUrls.login, { ...requestBody })
  },
  logout: async (): Promise<LogoutResponse> => {
    return service.post(requestUrls.logout)
  },
  loginAsHiddenCustomer: async (): Promise<LoginResponse> => {
    return service.post(requestUrls.previewLogin)
  },
  prolongate: async (
    requestBody: ProlongateRequest,
    options?: { headers?: Record<string, string> }
  ): Promise<ProlongateResponse> => {
    return service.post(requestUrls.prolongate, requestBody, options)
  },
  getUser: (): LocalMentorUserDataType | null => {
    if (typeof window === 'undefined') {
      return null
    }

    const isPreviewMode = sessionStorage.getItem('preview') === 'true'
    const storage = isPreviewMode ? sessionStorage : localStorage
    const userFromStorage = storage.getItem(authConfig.mentorToolsUserObjectKeyName)

    if (userFromStorage) {
      return JSON.parse(userFromStorage) as LocalMentorUserDataType
    }

    return null
  },

  registerContentCreator: async (requestBody: RegisterRequest): Promise<LoginResponse> => {
    return service.post(requestUrls.registerContentCreator, requestBody)
  },
  registerSingle: async (requestBody: RegisterRequest): Promise<LoginResponse> => {
    return service.post(requestUrls.registerSingle, requestBody)
  },
  registerDouble: async (requestBody: RegisterRequest): Promise<LoginResponse> => {
    return service.post(requestUrls.registerDouble, requestBody)
  },
  registerActivate: async (requestBody: RegisterActivateRequest): Promise<RegisterActivateResponse> => {
    return service.post(requestUrls.registerActivate, requestBody)
  },
  registerVerify: async (requestBody: RegisterVerifyRequest): Promise<RegisterVerifyResponse> => {
    return service.post(requestUrls.registerVerify, requestBody)
  },
  resetPassword: async (requestBody: ResetPasswordRequest): Promise<ResetPasswordResponse> => {
    return service.post(requestUrls.resetPassword, requestBody)
  },
  updatePassword: async (requestBody: UpdatePasswordRequest): Promise<AxiosResponse<UpdatePasswordResponse>> => {
    return service.post(globalRequestUrls.updatePassword, requestBody)
  }
}

export default authService

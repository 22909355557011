import { useMemo } from 'react'

import { Radio, RadioGroup, Stack, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'

import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'

import { getUserRole, UserRolesType } from 'src/utils/getUserRole'

import Icon from '../../../../@core/components/icon'
import {
  CommunityNotificationSettings,
  NotificationSettings,
  notificationSettingsType
} from '../../../../types/settings'
import { useNotificationSettingsForm } from '../../../../hooks/settings/notifications/useNotificationSettingsForm'

const StyledSwitch = styled(Switch)(({ theme }) => ({
  marginTop: theme.spacing(-3),
  marginBottom: theme.spacing(-3)
}))

const StyledFormControlLabel = styled(FormControlLabel)({
  marginLeft: 0
})

const StyledRadio = styled(Radio)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(1)
}))

const RadioGroupWrapper = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(9.5)
}))

type Props = {
  communityNotificationSettings: CommunityNotificationSettings
  notificationSettings: NotificationSettings
}

export const NotificationsEditor = ({ communityNotificationSettings, notificationSettings }: Props) => {
  const theme = useTheme()

  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.PROFILE_SETTINGS' })
  const { t: tNotifications } = useTranslation('translation', { keyPrefix: 'PAGE.NOTIFICATION_SETTINGS.FORM' })

  const { t: tOptions } = useTranslation('translation', { keyPrefix: 'PAGE.NOTIFICATION_SETTINGS.FORM.options' })
  const { t: tGlobal } = useTranslation()
  const userRole = getUserRole()

  const { control, onSubmit, watch, isSubmitting, isDirty } = useNotificationSettingsForm(
    communityNotificationSettings,
    notificationSettings
  )

  const isPreviewMode = useMemo(() => userRole === UserRolesType.HIDDEN_CUSTOMER, [userRole])

  const receive_email_notifications = watch('receive_email_notifications')
  const receive_in_app_notifications = watch('receive_in_app_notifications')

  return (
    <Stack spacing={theme.spacing(6)} marginTop={theme.spacing(6)} useFlexGap>
      <Stack spacing={theme.spacing(1.5)}>
        <Typography variant='h5'>{t('community_notifications_title')}</Typography>

        <Typography variant='body1'>{t('community_notifications_subtitle')}</Typography>
      </Stack>

      <Controller
        name='receive_email_notifications'
        control={control}
        render={({ field: { value, onChange } }) => (
          <Tooltip
            title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
            placement='top-start'
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            disableInteractive={false}
          >
            <Stack spacing={-theme.spacing(0.5)}>
              <FormControlLabel
                onChange={isPreviewMode ? undefined : onChange}
                checked={value}
                label={t('FORM.email_notifications_label')}
                control={<StyledSwitch disableRipple />}
              />
              <Typography variant='body1'>{tNotifications('email_notifications_description')}</Typography>
            </Stack>
          </Tooltip>
        )}
      />
      <Controller
        name='receive_in_app_notifications'
        control={control}
        render={({ field: { value, onChange } }) => (
          <Tooltip
            title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
            placement='top-start'
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            disableInteractive={false}
          >
            <Stack spacing={-theme.spacing(0.5)}>
              <FormControlLabel
                onChange={isPreviewMode ? undefined : onChange}
                checked={value}
                label={t('FORM.inapp_notifications_label')}
                control={<StyledSwitch disableRipple />}
              />
              <Typography variant='body1'>{tNotifications('inapp_notifications_description')}</Typography>
            </Stack>
          </Tooltip>
        )}
      />

      {(receive_email_notifications || receive_in_app_notifications) && (
        <RadioGroupWrapper>
          <Controller
            name='notification_settings_type'
            control={control}
            render={({ field: { value, onChange } }) => (
              <RadioGroup onChange={isPreviewMode ? undefined : onChange} value={value}>
                <Stack spacing={theme.spacing(4)} useFlexGap paddingY={theme.spacing(3)}>
                  {notificationSettingsType.map(option => (
                    <Tooltip
                      key={option}
                      title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
                      placement='top-start'
                      enterTouchDelay={0}
                      leaveTouchDelay={3000}
                      disableInteractive={false}
                    >
                      <Stack direction='row' alignItems='center'>
                        <StyledFormControlLabel
                          key={option}
                          control={<StyledRadio value={option} />}
                          label={tOptions(option)}
                        />
                        <Tooltip title={tNotifications(`options.${option}_tooltip`)} placement='right'>
                          <div
                            style={{ all: 'unset', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                            aria-label={tNotifications(`options.${option}_tooltip`)}
                          >
                            <Icon
                              style={{ marginLeft: theme.spacing(-2) }}
                              color={theme.palette.primary.main}
                              fontSize={theme.spacing(4.5)}
                              icon='tabler:help'
                            />
                          </div>
                        </Tooltip>
                      </Stack>
                    </Tooltip>
                  ))}
                </Stack>
              </RadioGroup>
            )}
          />
        </RadioGroupWrapper>
      )}
      <Stack spacing={theme.spacing(4)}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={theme.spacing(2)}
          padding={theme.spacing(2.5, 2)}
          color='#B7B4BE'
        >
          <Icon icon='tabler:info-circle' fontSize={14} />
          <Typography variant='body2'>
            {receive_email_notifications
              ? t('FORM.community_notifications_enabled')
              : t('FORM.community_notifications_disabled')}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction='row' spacing={theme.spacing(4)}>
        <LoadingButton
          loading={isSubmitting}
          variant='contained'
          disabled={!isDirty}
          onClick={onSubmit}
          color='primary'
        >
          {tGlobal('ACTION.save_changes')}
        </LoadingButton>
      </Stack>
    </Stack>
  )
}
